<template>
	<BaseLoading v-if="editRate.isLoading" />
	<form
		v-else
		class="form-edit-rate main-wrapper mt-4"
		@submit.prevent="handleSubmit"
	>
		<CRow>
			<CCol lg="12">
				<div class="form-group d-flex">
					<label for="shipping-fee">Shipping fee</label>
					<BaseInputNumber
						id="shipping-fee"
						v-model="$v.fee.$model"
						:decimal-scale="2"
						:is-valid="!$v.fee.$error"
						:invalid-feedback="feeErrors"
						prepend-text="฿"
						text-align="right"
						placeholder="0.00"
					/>
				</div>
				<hr v-if="!isDefault" class="mb-4">
			</CCol>
			<template v-if="!isDefault">
				<CCol lg="12">
					<h4 class="mb-4">
						Cart total
					</h4>
				</CCol>
				<CCol lg="12">
					<div class="form-group form-group-cart d-flex" data-test-id="form-group-price">
						<label for="price">Price</label>
						<BaseInputNumber
							id="price"
							v-model="$v.minPrice.$model"
							:decimal-scale="2"
							:is-valid="!$v.minPrice.$error"
							:invalid-feedback="$t('global.error.required')"
							prepend-text="฿"
							text-align="right"
							placeholder="0.00"
						/>
						<span class="to">to</span>
						<BaseInputNumber
							v-model="$v.maxPrice.$model"
							:decimal-scale="2"
							:is-valid="!$v.maxPrice.$error"
							:invalid-feedback="$t('global.error.required')"
							prepend-text="฿"
							text-align="right"
							placeholder="0.00"
						/>
					</div>
				</CCol>
				<CCol lg="12">
					<div class="form-group form-group-cart d-flex" data-test-id="form-group-weight">
						<label for="weight">Weight(kg)</label>
						<BaseInputNumber
							id="weight"
							v-model="$v.minWeight.$model"
							:is-valid="!$v.minWeight.$error"
							:invalid-feedback="$t('global.error.required')"
							text-align="right"
							placeholder="0"
						/>
						<span class="to">to</span>
						<BaseInputNumber
							v-model="$v.maxWeight.$model"
							:is-valid="!$v.maxWeight.$error"
							:invalid-feedback="$t('global.error.required')"
							text-align="right"
							placeholder="99"
						/>
					</div>
				</CCol>
				<CCol lg="12">
					<div class="form-group form-group-cart d-flex" data-test-id="form-group-quantity">
						<label for="quantity">Quantity</label>
						<BaseInputNumber
							id="quantity"
							v-model="$v.minQuantity.$model"
							:is-valid="!$v.minQuantity.$error"
							:invalid-feedback="$t('global.error.required')"
							text-align="right"
							placeholder="0"
						/>
						<span class="to">to</span>
						<BaseInputNumber
							v-model="$v.maxQuantity.$model"
							:is-valid="!$v.maxQuantity.$error"
							:invalid-feedback="$t('global.error.required')"
							text-align="right"
							placeholder="99"
						/>
					</div>
				</CCol>
			</template>
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="editRate.isUpdating"
			:remove-text="!isDefault ? 'Remove rate' : null"
			is-edit
			content-class="main-wrapper"
			data-test-id="form-action-panel"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'ShippingSetting'})"
			@onRemove="!isDefault ? $refs['modal-remove'].open() : null"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteShippingRate.bind(null, $route.params.rateId)"
			title="Delete this base shipping fee?"
			description="Deleting rate will affect upcoming orders immediately."
			@onSuccess="$router.push({ name: 'ShippingSetting' })"
		/>
	</form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { required, requiredIf, maxValue } from 'vuelidate/lib/validators';
import BaseModalConfirmDelete from '@/components/BaseModalConfirmDelete.vue';
import { priceToAPI } from '../assets/js/helpers';
import { MAXIMUM_FEE } from '../enums/shippings';

export default {
	name: 'ShippingRateEdit',
	validations: {
		fee: {
			required,
			maxValue: maxValue(MAXIMUM_FEE),
		},
		minPrice: {
			required: requiredIf((data) => {
				return !data.isDefault;
			}),
		},
		maxPrice: {
			required: requiredIf((data) => {
				return !data.isDefault;
			}),
		},
		minWeight: {
			required: requiredIf((data) => {
				return !data.isDefault;
			}),
		},
		maxWeight: {
			required: requiredIf((data) => {
				return !data.isDefault;
			}),
		},
		minQuantity: {
			required: requiredIf((data) => {
				return !data.isDefault;
			}),
		},
		maxQuantity: {
			required: requiredIf((data) => {
				return !data.isDefault;
			}),
		},
	},
	components: {
		BaseModalConfirmDelete,
	},
	data() {
		return {
			shippingMethodId: this.$route.params.shippingMethodId,
			rateId: this.$route.params.rateId,
			isDefault: null,
			fee: null,
			minPrice: null,
			maxPrice: null,
			minWeight: null,
			maxWeight: null,
			minQuantity: null,
			maxQuantity: null,
		};
	},
	computed: {
		...mapState('shippings', {
			editRate: (state) => state.shippingRates.edit,
		}),

		...mapGetters({
			rateInfo: 'shippings/rateInfo',
		}),

		feeErrors() {
			if (!this.$v.fee.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.fee.maxValue) {
				return 'Please input the maximum number 99,999,999.99';
			}

			return null;
		},
	},
	async created() {
		await this.getShippingRate(this.rateId);

		this.isDefault = this.rateInfo.isDefault;
		this.fee = this.rateInfo.fee;
		this.minPrice = this.rateInfo.minPrice;
		this.maxPrice = this.rateInfo.maxPrice;
		this.minWeight = this.rateInfo.minWeight;
		this.maxWeight = this.rateInfo.maxWeight;
		this.minQuantity = this.rateInfo.minQuantity;
		this.maxQuantity = this.rateInfo.maxQuantity;
	},
	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			getShippingRate: 'shippings/getShippingRate',
			updateShippingRate: 'shippings/updateShippingRate',
			deleteShippingRate: 'shippings/deleteShippingRate',
		}),

		async handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			const params = {
				fee: priceToAPI(this.fee),
				is_default: this.isDefault ? 1 : 0,
				min_price: priceToAPI(this.minPrice),
				max_price: priceToAPI(this.maxPrice),
				min_weight: this.minWeight,
				max_weight: this.maxWeight,
				min_quantity: this.minQuantity,
				max_quantity: this.maxQuantity,
			};

			if (params.is_default) {
				delete params.min_price;
				delete params.max_price;
			}

			await this.updateShippingRate({
				id: this.rateId,
				params,
			});

			this.fee = this.rateInfo.fee;
			this.minPrice = this.rateInfo.minPrice;
			this.maxPrice = this.rateInfo.maxPrice;
		},
	},
};
</script>


<style lang="scss" scoped>
	.form-group {
		label {
			flex: 1;
			max-width: rem(160);
			margin-bottom: 0;
			position: relative;
			top: 8px;
		}

		.to {
			@include typo-body-2;

			position: relative;
			top: 8px;
			margin: 0 rem(24);
		}


		// .form-group-cart
		&-cart {
			margin-bottom: rem(32);
		}

		::v-deep .input-group {
			max-width: rem(140);
		}
	}

	.btn-submit {
		min-width: auto;
	}

	.btn-remove {
		margin-right: auto;
	}
</style>
